@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body { 
  background: linear-gradient(135deg, #190023 20%, rgb(28, 0, 52) 30%, rgb(20, 0, 27) 90%);
  color: #000; 
  min-height: 100vh; 
  font-family: Arial, sans-serif;
}
/* == Estylos de Textos == */
.text-10-b{ font-size: 10px; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-10-s{ font-size: 10px; font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-12-b{ font-size: 12px; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-12-s{ font-size: 12px; font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-14-b{ font-size: 14px; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-14-s{ font-size: 14px; font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-18-b{ font-size: 18px; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-18-s{ font-size: 18px; font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-20-b{ font-size: 20px; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-20-s{ font-size: 20px; font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-24-b{ font-size: 24px; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-24-s{ font-size: 24px; font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-30-b{ font-size: 30px; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.text-30-s{ font-size: 30px; font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.hw-title-import{ color: #fff; font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }

.title-h1-s { font-weight: normal; font-family: 'Roboto', sans-serif; text-decoration:none !important; }
.title-h1-b { font-weight: bold; font-family: 'Roboto', sans-serif; text-decoration:none !important; }

.tbg-withe { color: rgb(252, 252, 252);}
.tbg-black { color: rgb(0, 0, 0);}
.tbg-black2 { color: #292929; font-weight: bolder;}
.tbg-black-5 { color: rgb(0, 0, 0, 0.5);}
.tbg-black-7 { color: rgb(0, 0, 0, 0.7);}
.tbg-black-9 { color: rgb(0, 0, 0, 0.9);}
.tbg-gris { color: rgb(204, 204, 204);}.tbg-grey-5 { color: rgb(204, 204, 204, 0.5);}.tbg-grey-7 { color: rgb(204, 204, 204, 0.7);}.tbg-grey-9 { color: rgb(204, 204, 204, 0.9);}
.tbg-acua { color: #079cb6;}
.tbg-acua-o { color: hwb(204 0% 69%);}.tbg-acua-bo { font-weight: bold; color: #003050;}
.tbg-orange { color: rgb(252, 105, 5);}
.tbg-gris-c { color: #424242;}
.tbg-gris-o { color: #292828;}
.tbg-gris-ro { color: #5d0202;}

.link-back-w {color: #fff; outline: none;text-decoration: none;}
.link-back-w a{color: #fff; outline: none;text-decoration: none;}
.link-back-w a:link {  color: #fff;}
.link-back-w a:visited {  color: #fff;}
.link-back-w a:hover { color: #fff; }
.link-back-w a:active {  color: #fff;}

.link-back-g {color: #131722; outline: none; font-size: 14px; font-weight: bold; text-decoration:none !important; font-family: Montserrat,"Open Sans",sans-serif;}
.link-back-g a{color: #131722; outline: none;text-decoration: none;}
.link-back-g a:link {  color: #131722;}
.link-back-g a:visited {  color: #131722;}
.link-back-g a:hover { color: #131722; }
.link-back-g a:active {  color: #131722;}

/* == Estylos de Links == */
.link-back {font-size: 14px;font-weight: bold;display: inline-block;padding: 5px 5px; outline: none;text-decoration: none; cursor: pointer;}
.link-back-12 {font-size: 12px;font-weight: bold;display: inline-block;padding: 5px 5px; outline: none;text-decoration: none; cursor: pointer;}
.link-sub-12ws {font-size: 20px;font-weight: bold;display: inline-block;padding: 5px 5px;}
.link-sub-12ws a {color: #000;outline: none;text-decoration: none;}
.link-sub-12ws a:link {  color: #079cb6;}
.link-sub-12ws a:visited {  color: #424242;}
.link-sub-12ws a:focus { padding-bottom: 3px; }
.link-sub-12ws a:hover { color: #079cb6; padding-bottom: 3px; }
.link-sub-12ws a:active {  color: #424242;}

.link-sub-12wo {font-size: 20px;font-weight: bold;display: inline-block;padding: 5px 5px;}
.link-sub-12wo a {color: #000;outline: none;text-decoration: none;}
.link-sub-12wo a:link {  color: #079cb6;}
.link-sub-12wo a:visited {  color: #003050;}
.link-sub-12wo a:focus { padding-bottom: 3px; }
.link-sub-12wo a:hover { color: #079cb6; padding-bottom: 3px; }
.link-sub-12wo a:active {  color: #003050;}

.link-mppl {font-size: 20px;font-weight: bold;}
.link-mppl a {color: #fff;outline: none;text-decoration: none;}
.link-mppl a:link {  color: #079cb6;}
.link-mppl a:visited {  color: #003050;}
.link-mppl a:focus { padding-bottom: 3px; }
.link-mppl a:hover { color: #079cb6; padding-bottom: 3px; }
.link-mppl a:active {  color: #003050;}
/* == Estylos de fondos background == */
.bg-withe { background-color: rgb(252, 252, 252);}
.bg-black-n { background-color: rgb(0, 0, 0)}
.bg-gris-n { background-color: rgb(204, 204, 204, 0.7);}
.bg-acua-n { background-color: rgb(7, 156, 182);}
.bg-orange-n { background-color: rgb(252, 105, 5);}
.bg-gris-c { background-color: #424242;}
.bg-gris-o { background-color: #292828;}

/* Estilo base para inputs, selects y textarea */
.Form-InputsControls,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="date"],
input[type="tel"],
input[type="url"],
input[type="search"],
select,
textarea,
.select__control { /* React-Select */
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  appearance: none;
}

/* Cuando está enfocado */
.Form-InputsControls:focus,
input:focus,
select:focus,
textarea:focus,
.select__control--is-focused {
  border-color: #4C0865;
  box-shadow: 0 0 5px rgba(255, 87, 34, 0.5);
  outline: none;
}

/* React-Select: estilos para los valores seleccionados */
.select__multi-value {
  background-color: #4C0865;
  color: white;
  border-radius: 3px;
  padding: 3px 5px;
}

/* React-Select: estilos para las etiquetas dentro del select */
.select__multi-value__label {
  color: white;
}

/* React-Select: estilos para la "X" de eliminar */
.select__multi-value__remove {
  background-color: transparent;
  color: white;
  cursor: pointer;
  transition: 0.3s;
}

.select__multi-value__remove:hover {
  background-color: rgba(255, 87, 34, 0.5);
}

/* React-Select: estilos del menú desplegable */
.select__menu {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* React-Select: opciones dentro del menú */
.select__option {
  padding: 10px;
  color: #333;
  transition: 0.2s;
  cursor: pointer;
}

.select__option:hover {
  background-color: #4C0865;
  color: white;
}

/* React-Select: opción seleccionada */
.select__option--is-selected {
  background-color: #4C0865;
  color: white;
}

/* React-Select: opción cuando está activa */
.select__option--is-focused {
  background-color: rgba(255, 87, 34, 0.3);
  color: #333;
}

/* Modo oscuro */
body.dark-mode .select__control {
  background-color: #222;
  border-color: #555;
  color: #fff;
}

body.dark-mode .select__multi-value {
  background-color: #ff5722;
}

body.dark-mode .select__menu {
  background-color: #333;
  border-color: #777;
}

body.dark-mode .select__option {
  color: #fff;
}

body.dark-mode .select__option:hover {
  background-color: #ff5722;
  color: white;
}

/* Cuando el elemento está enfocado (clic o tab) */
.Form-InputsControls:focus,
input:focus,
select:focus,
textarea:focus {
  border-color: #4C0865;
  box-shadow: 0 0 5px rgba(255, 87, 34, 0.5);
  outline: none;
}

/* Estilos para select */
select {
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='%234C0865' d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px;
  padding-right: 30px;
}

/* Input deshabilitado */
.Form-InputsControls:disabled,
input:disabled,
select:disabled,
textarea:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Placeholder (Texto dentro del input cuando está vacío) */
.Form-InputsControls::placeholder,
input::placeholder,
textarea::placeholder {
  color: #aaa;
  font-style: italic;
}

/* Cuando el usuario ingresa datos válidos */
.Form-InputsControls:valid,
input:valid,
select:valid,
textarea:valid {
  border-color: #ccc;
}

/* Cuando el usuario ingresa datos inválidos */
.Form-InputsControls:invalid,
input:invalid,
select:invalid,
textarea:invalid {
  border-color: #dc3545;
}

/* Input con error en validación */
.Form-InputsControls.is-invalid,
input.is-invalid,
select.is-invalid,
textarea.is-invalid {
  border-color: #dc3545;
  background-color: #fff5f5;
}

/* Input con validación exitosa */
.Form-InputsControls.is-valid,
input.is-valid,
select.is-valid,
textarea.is-valid {
  border-color: #360F5B;
  background-color: #f5fff5;
}

/* Inputs tipo password para ocultar puntos de escritura */
input[type="password"] {
  font-family: Verdana, sans-serif;
  letter-spacing: 2px;
}

/* Input tipo checkbox y radio */
.form-check-input {
  width: 18px;
  height: 18px;
  accent-color: #ff5722;
}

/* Deshabilitar efecto de autocompletar amarillo en Chrome */
.Form-InputsControls:-webkit-autofill,
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}

/* Estilo específico para dark mode */
body.dark-mode .Form-InputsControls,
body.dark-mode input,
body.dark-mode select,
body.dark-mode textarea {
  background-color: #222;
  color: #fff;
  border-color: #555;
}

body.dark-mode .Form-InputsControls:focus,
body.dark-mode input:focus,
body.dark-mode select:focus,
body.dark-mode textarea:focus {
  border-color: #ff5722;
  box-shadow: 0 0 5px rgba(255, 87, 34, 0.5);
}


/*** Diseño fondo de body gradiente para Login y paginas principales ***/
.iBackground-Desk { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh; 
  background-image: url("https://provenco.co/images/PORTADA.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: content; 
}
.iBackground-Movil { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-image: url("https://twilio.chpriv.com/media/app_images/Background_movil.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /***opacity: 0.31; ***/
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s; 
}
/** Funcion para Body **/
.iBackBody-Desk {  
  width: 100%;
  height: 100%; 
  background-image: url("https://twilio.chpriv.com/media/app_images/Background_screen.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%; 
}
.iBackBody-Movil {  
  width: 100%;
  height: 100vh;
  background-image: url("https://twilio.chpriv.com/media/app_images/Background_movil.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; 
}
.ToiVRute {
  position: relative;
  display: flex;
  width:auto;
  height: 40px; 
  margin-top: 3rem; 
  padding: 1rem;
  overflow: hidden;
  z-index: 10;
}
.BSessionSale {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: auto;
  padding: 1rem;
  margin: 5px auto;  
} 
/* Stylos de Sistema Registro  */
code {  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',    monospace;}
.primary_color {  color: #bc6aff}
.noneDecoration {  text-decoration: none !important;}
.link_text { padding-left: 1rem; text-transform: uppercase; font-weight: 600; color: #585858}
.App { text-align: center; }
.App-logo { height: 40vmin; pointer-events: none;}
@media (prefers-reduced-motion: no-preference) {  .App-logo { animation: App-logo-spin infinite 20s linear; } }
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {  color: #61dafb;}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}