
/*** Page Movie ***/
.iVslider-item { position: relative; display: flex; width: 100%; height: auto; height: 500px; overflow: hidden;}
.iVContainer-g {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100px;
    margin: 0 auto;
    padding: 0 0;
    overflow: hidden;
} 
 
.BozQr {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: auto; 
    margin: 0 auto;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
    text-align: center; 
} 

.caja-Qr {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #000;
    font-size: 1rem;
    font-weight: 700;
}

.QRIcons {
    position: absolute;
    display: flex;
    flex-direction: column;
    top:-20px; 
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 45px;
    height: 45px; 
    margin: 0 auto;
    border: 0px;
    border-radius: 50%; 
    background-color: #fff;
}
.ViewImg-Btn { 
    background: url('https://provenco.co/images/icono.png') no-repeat center center; 
    background-size: 45px 45px;
}

.iVRute {
    position: relative;
    display: flex;
    width:auto;
    height: auto;  
    margin-top: 4rem;
    padding: 5px 10px; 
    overflow: hidden;
}
.ImgBackMovie {  
    width: 100%;
    background-repeat:  no-repeat;
    background-position: center; 
    object-fit: cover; 
}
.Ec-ctitle-title { font-size: 45px; line-height: 1.1; font-family: Montserrat,"Open Sans",sans-serif;  white-space: normal; font-weight: bold; }
/*** Contenido y stylos para page movie ***/
.custom-link-rute { color: #010f37; font-size: 12px; font-weight: bold; text-decoration:none !important; font-family: Montserrat,"Open Sans",sans-serif;}
.Back-elemte-corace{ 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 100%;
    height: 100%; 
    background: rgba(0, 0, 0, 0.3); 
}
 
  /* Agrega estilos adicionales según sea necesario */
  .iVEcontent-slider { display: flex; flex-direction: column; width: 100%; height: auto; justify-content:flex-start; align-items:flex-start; background-color: #131722;}
  .iVTitle-1 { font-size: 25px; font-family: Montserrat,"Open Sans",sans-serif; font-weight: bold; }
  .iVEc-slider-l { flex: 0 0 500px; flex-direction: column; height: auto; padding: 2rem; }
  .iVEc-slider-r { flex: 1; height: auto; }
  .iVEc-column { display: block;padding: 3px; margin: 10px auto; color: #fff; }
  .iVEc-ctitle { font-size: 72px;
  line-height: 1.1;
  font-family: Montserrat,"Open Sans",sans-serif;
  white-space: normal;
  font-weight: bold;  
  }
  .vi-modal-dark { background-color: rgba(0, 0, 0, 0.7); padding: 2rem 0px; }


@media screen and (max-width: 720px){
 /* Agrega estilos adicionales según sea necesario */
 .iVEcontent-slider { display: flex; flex-direction: column; width: 100%; height: auto; justify-content:flex-start; align-items:flex-start; background-color: #131722;}
 .iVTitle-1 { font-size: 25px; font-family: Montserrat,"Open Sans",sans-serif; font-weight: bold; }
 .iVEc-slider-l { flex: 0 0 100%; flex-direction: column; height: auto; padding: 0; }
 .iVEc-slider-r { flex: 1; height: auto; }
 .iVEc-column { display: block;padding: 3px; margin: 10px auto; color: #fff; }
 .iVEc-ctitle { font-size: 72px;
 line-height: 1.1;
 font-family: Montserrat,"Open Sans",sans-serif;
 white-space: normal;
 font-weight: bold;  
 }

}
