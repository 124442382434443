
.footer { 
  color: #fff;
  padding: 0px 0px;
  width: 100%;  
} 

.footer-row {
  position: relative;
  display: flex;
  flex-direction: column; 
  width: 100%;
  padding: 1rem;  
  overflow: hidden; 
}

.footer-col {
  position: relative;
  display: flex; 
  flex-direction: row;
  width: 100%;
  padding: 0px; 
  margin: 0 auto;
  align-items: flex-start;justify-content: center; 
}

.footer-col>div { display: flex; flex-direction: column; width: 300px; align-items: flex-start;justify-content: flex-start; }
.footer-com {
  position: relative;
  display: flex; 
  width: 100%;
  padding: 4px 3rem;
}

.footer-col h4 {
  font-size: 14px;
  margin-bottom: 15px;
  border-bottom: 2px solid #ff6600;
  display: inline-block;
  padding-bottom: 5px;
}

.footer-col ul {
  list-style: none;
  padding: 0;
}

.footer-col ul li {
  margin-bottom: 10px;
  font-size: 14px;
}

.footer-col ul li a {
  color: #ccc;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-col ul li a:hover {
  color: #ff6600;
}

.social-links {
  display: flex; 
  flex-direction: row;
  align-items: center;
}
.social-links svg {
  margin: 0px 4px;
}
.footer-col .social-links a {
  display: inline-block;
  margin: 0 8px;
  color: #fff;
  font-size: 18px;
  transition: color 0.3s;
}

.footer-col .social-links a:hover {
  color: #ff6600;
}

.footer-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  text-align: center;
  padding: 15px 0; 
  margin: 0 auto;
  font-size: 14px;
}

.footer-bottom span { margin: 1rem;}

/*== Estylos Madia Query max-820 ==*/
@media screen and (max-width: 760px){ 
  .footer-bottom { width: 90%; } 
  .footer-col>div {width: 50%;}
}
